import React from "react";
import { ServiceTitTxt, CardInfo } from "./components/service";

const ServiceIndex = () => {

	return (
		<>
			<section className='service'>
				<article className='visual'>
					<div className="visual-image"><img src="/images/sub/bg_visual02.png" alt="visual" /></div>
					{/* <h2>서비스</h2> */}
					<div className="content">
						<p>We are opening a world</p>
						<p>where everyone can receive</p>
						<p className="highlight">comfort and healing.</p>
					</div>
				</article>
				<article className='content'>
					<div className='txt-box'>
						<ServiceTitTxt />
					</div>
					<div className='info'>
						<CardInfo />
					</div>
				</article>
			</section>
		</>
	);
};

export default ServiceIndex;
