import React, { useContext } from "react";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const BigglzVision = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>비글즈 목표</>
          ) : myLanguage === ENG ? (
            <>Bigglz&#39;s Mission</>
          ) : null
        }
      </h3>
      <p className="txt1">
        {
          myLanguage === KOR ? (
            <>
              우리는
              <span>
                <strong>따뜻하고 즐거운 서비스</strong>를 제공해요
              </span>
            </>
          ) : myLanguage === ENG ? (
            <>
              <strong>I&#39;m Your Friends, bigglz!</strong>
              <span>We provide a warm and joyful service.</span>
            </>
          ) : null
        }
      </p>
      <p className="txt2 txt-vision">
        {
          myLanguage === KOR ? (
            <>
              <strong>비글즈</strong>는 시각적 즐거움과 감정적 만족감 그리고 캐릭터 페르소나가 담긴 감성적 힐링 대화로 스트레스를 해소하고, 위로해주는 <strong>반려마음챙김</strong> 기업입니다.
            </>
          ) : myLanguage === ENG ? (
            <>
              Driven by a deep understanding of the unique mental health
              challenges faced by young people today, we are committed to
              offering a digital sanctuary (safe place) where they can find
              comfort, connection, and empowerment through caring for a
              digital pet.
            </>
          ) : null
        }
      </p>
      <div className='img'>
        <figure>
          <img src='/images/sub/img_visual01.png' alt='비글즈 건물' />
        </figure>
      </div>
      <div className="txt-area">
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                비글즈는 2021년 창업한 스타트업으로<br />
                모든 이들의 마음에 따스한 위로를 줄 수 있는 다양한 서비스를 제공하고 있어요.
              </>
            ) : myLanguage === ENG ? (
              <>
                ‘Bigglz’ is a startup founded in 2021<br />
                that provides a variety of services that can provide warmth comfort to everyone.
              </>
            ) : null
          }
        </p>
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                사람들의 일상에 따뜻함과 행복함이 가득하기를<br />
                바라는 큰 꿈을 가진 사람들이 모여 그 꿈을 실현해 나가고 있어요.
              </>
            ) : myLanguage === ENG ? (
              <>
                We have an ambitious dream of filling<br />
                everyone&#39;s heart with warmth, and happiness.
              </>
            ) : null
          }
        </p>
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                모바일 플랫폼 사업을 핵심으로 캐릭터 IP 사업,<br />
                오프라인 IP 기반 힐링 스페이스 사업을 진행하고 있으며<br />
                진정한 힐링 글로벌 브랜드로 도약하기 위해<br />
                타겟 특화 멘탈케어 대화형 AI 엔진 개발을 추진하고 있습니다.
              </>
            ) : myLanguage === ENG ? (
              <>
                We are establishing ourselves as a global mental wellness brand with our mobile digital pet platform (conversational AI) , character IP business and offline IPbased wellness spaces.
              </>
            ) : null
          }
        </p>
        <p className="txt2">
          {
            myLanguage === KOR ? (
              <>
                함께 위로하고 함께 즐기는 세상, 비글즈가 일상 속 행복 전달소가 되어드릴게요.
              </>
            ) : myLanguage === ENG ? (
              <>
                In a world where we comfort and enjoy together, Bigglz will become a source of happiness in everyday life.
              </>
            ) : null
          }
        </p>
      </div>
    </>
  );
}

export const HistoryInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>비글즈가 걸어온 길</>
          ) : myLanguage === ENG ? (
            <>Bigglz’s Career</>
          ) : null
        }
      </h3>
      <p className="txt1">
        {
          myLanguage === KOR ? (
            <>
              Bigglz는 오늘도 <strong>한 걸음</strong> 나아가고 있습니다.
            </>
          ) : myLanguage === ENG ? (
            <>
              Bigglz is taking another <strong>step</strong> forward today.
            </>
          ) : null
        }
      </p>
      <div className="history-area">
        <div className="year-area">
          <div className="year">2021</div>
          <div className="month-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="month-area">
                    <div className="month">05월</div>
                    <div className="cnt">
                      <span>주식회사 비글즈 설립</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">12월</div>
                    <div className="cnt">
                      <span>‘대형베이커리카페 카페gg’, ‘아트gg갤러리’ 오픈</span>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="month-area">
                    <div className="month">May</div>
                    <div className="cnt">
                      <span>Establishment of Bigglz Co., Ltd.</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Dec</div>
                    <div className="cnt">
                      <span>‘Large Bakery Cafe gg’ and ‘Art gg Gallery’ opened</span>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
        <div className="year-area">
          <div className="year">2022</div>
          <div className="month-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="month-area">
                    <div className="month">07월</div>
                    <div className="cnt">
                      <span>국내버전 비글즈 앱 런칭</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">12월</div>
                    <div className="cnt">
                      <span>Pre-시리즈A 15억원 투자유치</span>
                      <span>상표등록특허 24건, 디자인출원특허 5건</span>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="month-area">
                    <div className="month">Jul</div>
                    <div className="cnt">
                      <span>Launch of domestic version of Bigglz app</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Dec</div>
                    <div className="cnt">
                      <span>Attracted investment of 1.5 billion won in Pre-Series A</span>
                      <span>trademark registration patents, 5 design application patents</span>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
        <div className="year-area">
          <div className="year">2023</div>
          <div className="month-box">
            {
              myLanguage === KOR ? (
                <>
                  <div className="month-area">
                    <div className="month">02월</div>
                    <div className="cnt">
                      <span>주식회사 랩투아이 MOU 체결</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">04월</div>
                    <div className="cnt">
                      <span>영어버전 비글즈 앱 런칭</span>
                      <span>기업부설연구소 인정 취득</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">05월</div>
                    <div className="cnt">
                      <span>초기창업패키지 지원사업 창업기업 최우수평가 획득</span>
                      <span>여성기업확인서 취득</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">08월</div>
                    <div className="cnt">
                      <span>기술특허출원 4종</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">09월</div>
                    <div className="cnt">
                      <span>설기 아바톡 앰버서더 선정</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">10월</div>
                    <div className="cnt">
                      <span>페르소나 챗봇 Agent AI 출시</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">11월</div>
                    <div className="cnt">
                      <span>마인드가드너 심리코칭센터 MOU체결</span>
                      <span>중소벤처기업부의 민간 투자주도형 기술창업 지원 프로그램 ‘딥테크 팁스(TIPS)’ 선정 및 필로소피아벤처스 3억원 투자유치</span>
                      <span>국내유니콘 기업들의 글로벌진출파트너 Relevant Skills Development, Inc,(https://relevant.world/)계약 체결</span>
                    </div>
                  </div>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="month-area">
                    <div className="month">Feb</div>
                    <div className="cnt">
                      <span>MOU signed with Lab2I Co., Ltd.</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Apr</div>
                    <div className="cnt">
                      <span>English version of Bigglz app launched</span>
                      <span>Acquired recognition as a corporate research institute</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">May</div>
                    <div className="cnt">
                      <span> Acquired the highest evaluation as a start-up company in the initial start-up package support project</span>
                      <span>Acquired women’s enterprise certification</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Aug</div>
                    <div className="cnt">
                      <span>4 types of technology patent applications</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Sep</div>
                    <div className="cnt">
                      <span>Selected as Seolgi Avatalk Ambassador</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Oct</div>
                    <div className="cnt">
                      <span>Release of Persona chatbot Agent AI</span>
                    </div>
                  </div>
                  <div className="month-area">
                    <div className="month">Nov</div>
                    <div className="cnt">
                      <span>MOU signed with Mindgardner Psychological Coaching Center</span>
                      <span>Selected as ‘Deep Tech TIPS’, a private investment-led technology startup support program by the Ministry of SMEs and Startups, and attracted 300 million won in investment from Philosophia Ventures</span>
                      <span>Execute a contract with Relevant Skills Development as our global expansion partner.</span>
                    </div>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  );
}

const partnerList1 = ['sports2i', 'lab2ai', 'darmCulture', 'mindguardner', 'relevant'];
const partnerList2 = ['google', 'gowell', 'bespinGlobal', 'datePop', 'dongram', '2bytes', 'soulBrain', 'materialsPark', 'furato', 'life'];
const partnerList3 = ['zenworks', 'philosophiaVentures', 'joshuaPartners', 'eCapitanlnet'];

export const ComPartner = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <h3>
        {
          myLanguage === KOR ? (
            <>
              비글즈 협력사
            </>
          ) : myLanguage === ENG ? (
            <>
              Bigglz Partner Company
            </>
          ) : null
        }
      </h3>
      <p className="txt1">

        {
          myLanguage === KOR ? (
            <>
              국내외 주요 업체들과<br />
              <strong>협력하며</strong> 서비스를 키워나가고 있습니다.
            </>
          ) : myLanguage === ENG ? (
            <>
              We are developing our services by
              <span><strong>collaborating</strong> with major domestic and foreign companies.</span>
            </>
          ) : null
        }
      </p>
      <div className="partner-area">
        <div className="tag">
          {
            myLanguage === KOR ? (
              <>
                # 주요 협력 파트너
              </>
            ) : myLanguage === ENG ? (
              <>
                # Main cooperation partner
              </>
            ) : null
          }
        </div>
        <ul className="partner-list">
          {
            partnerList1.map((value, index) => (
              <li key={index}>
                <img src={`/images/partner/com_${value}.png`} alt={value} />
              </li>
            ))
          }
        </ul>
        <div className="tag">
          {
            myLanguage === KOR ? (
              <>
                # 캐릭터 IP 및 온오프라인 파트너
              </>
            ) : myLanguage === ENG ? (
              <>
                # Character IP & On/offline partner
              </>
            ) : null
          }
        </div>
        <ul className="partner-list">
          {
            partnerList2.map((value, index) => (
              <li key={index}>
                <img src={`/images/partner/com_${value}.png`} alt={value} />
              </li>
            ))
          }
        </ul>
        <div className="tag">
          {
            myLanguage === KOR ? (
              <>
                # 투자사 및 글로벌 파트너
              </>
            ) : myLanguage === ENG ? (
              <>
                # Investment & Global partner
              </>
            ) : null
          }
        </div>
        <ul className="partner-list">
          {
            partnerList3.map((value, index) => (
              <li key={index}>
                <img src={`/images/partner/com_${value}.png`} alt={value} />
              </li>
            ))
          }
        </ul>
      </div>
    </>
  );
}