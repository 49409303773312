import React, { useContext } from "react";
import { outLinks } from "../../../../routes/routes";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const AppInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className="persona-box download" style={{
        'backgroundImage': `url('/images/sub/AIbusuness_img_2.png')`,
        'backgroundPosition': 'center',
        'backgroundSize': 'cover',
        'backgroundRepeat': 'no-repeat'
      }}>
        <>
          {
            myLanguage === KOR ? (
              <>
                <p>지금 바로 다운받아보세요.</p>
                <div className="btn-area">
                  <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                    <img src="/images/common/btn_appstore.png" alt="app store 에서 다운로드 하기" />
                  </a>
                  <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                    <img src="/images/common/btn_googleplay.png" alt="google play에서 다운로드 하기" />
                  </a>
                </div>
              </>
            ) : myLanguage === ENG ? (
              <>
                <p>Download it right now.</p>
                <div className="btn-area">
                  <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                    <img src="/images/eng/eng_btn_appstore.png" alt="Download on the App Store" />
                  </a>
                  <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                    <img src="/images/eng/eng_btn_googleplay.png" alt="Get it on Google Play" />
                  </a>
                </div>
              </>
            ) : null
          }
        </>
      </div>
      <div className="persona-box intro">
        <div className="intro-area">
          <p>
            {
              myLanguage === KOR ? (
                <>
                  <span>
                    마음을 녹여줄  
                  </span>
                  <span>
                    <strong>따뜻한 친구들이 기다리고 있어요.</strong>
                  </span>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <span>
                      <strong>Heart-Warming</strong>
                    </span>
                    <span>AI friends are waiting for you.</span>
                    <div className="tags">
                      <i># customized</i>
                      <i># Best friend</i>
                      <i># Various Persona</i>
                    </div>
                  </div>
                  <div className="image"><img src="/images/sub/AIbusuness_img_3.png" alt="sub-img3" /></div>
                </>
              ) : null
            }
          </p>
        </div>
      </div>
      <div className="persona-box app">
        <ul>
          <li>
            {
              myLanguage === KOR ? (
                <div className="app-intro">
                  <div className="photo">
                    <img src="/images/sub/hiing_appinfo1.png" alt="포그니와의 대화" />
                  </div>
                  <div className="txt">
                    <span>나와 나눴던</span>
                    <span>
                      <strong>모든 대화를 기억하고,</strong>
                    </span>
                    <span>안부를 물어봐줄게요.</span>
                  </div>
                </div>
              ) : myLanguage === ENG ? (
                <div className="app-intro">
                  <div className="txt">
                    <p>Hiing is your true AI friend that remembers your worries and the <span>conversations</span> we’ve shared, checking in with you from a place of <span>genuine care.</span></p>
                  </div>
                  <div className="photo">
                    <img src="/images/eng/AIbusuness_img_4.png" alt="포그니와의 대화" />
                  </div>
                </div>
              ) : null
            }
          </li>
          <li
            className="second"
            style={{
              'backgroundImage': `url('/images/sub/AIbusuness_img_5_bg.png')`,
              'backgroundPosition': 'center',
              'backgroundSize': 'cover',
              'backgroundRepeat': 'no-repeat'
            }}
          >
            {
              myLanguage === KOR ? (
                <div className="app-intro">
                  <div className="photo">
                    <img src="/images/sub/hiing_appinfo2.png" alt="포그니와의 대화" />
                  </div>
                  <div className="txt">
                    <span>평소 쉽게 꺼내지 못한</span>
                    <span>이야기와 마음들,</span>
                    <span>
                      <strong>당신의 편에서 들어줄게요.</strong>
                    </span>
                  </div>
                </div>
              ) : myLanguage === ENG ? (
                <div className="contents">
                  <div className="left">
                    <div className="photo">
                      <img src="/images/eng/AIbusuness_img_5-1.png" alt="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="txt">
                      <p>In collaboration with <span>mental health</span> professionals, all conversations are <span>securely protected,</span> offering you meaningful and effective dialogue that can inspire real <span>positive change.</span></p>
                    </div>
                    <div className="image">
                      <img src="/images/sub/AIbusuness_img_5-2.png" alt="" />
                    </div>
                  </div>
                </div>
              ) : null
            }
          </li>
          <li className="third">
            {
              myLanguage === KOR ? (
                <div className="contents">
                  {/* <div className="bg">
                    <img src="/images/eng/AIbusuness_img_6.png" alt="" />
                  </div> */}
                  <div className="txt">
                    <span>기쁜 일에는 같이</span>
                    <span>
                      <strong>축하해 주고 힘들 때도</strong>
                    </span>
                    <span>
                      위로가 되어줄 거예요.
                    </span>
                  </div>
                </div>
              ) : myLanguage === ENG ? (
                <div className="contents">
                  {/* <div className="photo">
                    <img src="/images/eng/eng_hiing_appinfo3.png" alt="포그니와의 대화" />
                    <img src="/images/sub/emotion_pogny.webp" alt="포그니 모션" className="motion"/>
                  </div> */}
                  <div className="txt">
                    <p>Hiing understands your heart’s desires, <span>connects with your emotions,</span> and turns that understanding into <span>empathetic actions,</span> helping you build <span>genuine connections.</span></p>
                  </div>
                  <div className="bg">
                    <img src="/images/eng/AIbusuness_img_6.png" alt="" />
                  </div>
                  <div className="bg-animate"><img src="/images/sub/emotion_pogny_joyful.webp" alt="animate" /></div>
                </div>
              ) : null
            }
          </li>
          <li className="fourth">
            {
              myLanguage === KOR ? (
                <div className="contents">
                </div>
              ) : myLanguage === ENG ? (
                <div className="contents">
                  <div className="image">
                    <img src="/images/eng/AIbusuness_img_7.png" alt="" />
                  </div>
                  <div className="txt">
                    <p>By writing your <span>self-compliment diary</span> daily, Hiing recognizes and evaluates your compliments, creating <span>personalized AI compliment cards</span> based on <span>context-driven image generation</span> to boost your <span>self-esteem.</span></p>
                  </div>
                </div>
              ) : null
            }
          </li>
        </ul>
      </div>
      <div className="persona-box outlink">
        {
          myLanguage === KOR ? (
            <>
              <dl>
                <dt>
                  <span>하잉(Hiing) 친구들을</span>
                  <span>
                    <strong>조금 더 편하게 </strong>만날 수도 있어요
                  </span>
                </dt>
                <dd>
                  <span>카카오톡 채널 및 페이스북 메신저에 </span>
                  <span>
                    <strong>‘포그니’</strong>와 <strong>‘하나(HANA)’</strong>를 검색해주세요.
                  </span>
                </dd>
              </dl>
            </>
          ) : myLanguage === ENG ? (
            <>
              <dl>
                <dt>
                  <span>“You can connect with Hiing’s friends more easily and personally, bringing them closer to you than ever before.”</span>
                  {/* <span>
                    You can also meet
                  </span>
                  <span>
                    <strong>Hiing friends more comfortably.</strong>
                  </span> */}
                </dt>
                {/* <dd>
                  <span>
                    Please search for <strong>'pogny' </strong>and <strong>'HANA' </strong>on KakaoTalk 
                    <span> channel and Facebook Messenger.</span>
                  </span>
                </dd> */}
                <div className="image"><img src="/images/eng/AIbusuness_img_8.png" alt="" /></div>
              </dl>
            </>
          ) : null
        }
      </div>
    </>
  )
}

export const TechnologyInfo = () => {
  const { myLanguage } = useContext(BigglzContext);

  return (
    <>
      <div className="persona-box-wrap">
        <div className="persona-box service1">
          <h3>
            {
              myLanguage === KOR ? (
                <>
                  <span>비글즈의 차별화된</span>
                  <span>
                    <strong>페르소나 Agent AI 힐링봇 기술</strong>
                  </span>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <span>Introducing Bigglz</span>
                  <span><strong>Find Comfort in Conversations</strong></span>
                  <span><strong>That Understand You.</strong></span>
                </>
              ) : null
            }
          </h3>
          <div className="service-area">
            {
              myLanguage === KOR ? (
                <>
                  <div className="item">
                    감성형 페르소나 챗봇 <strong>Agent AI 기술 도입</strong>을 통해 진정한 힐링의 가치를 전달합니다.
                  </div>
                  <div className="item">
                    <strong>RAG 기술과 sLLM을 자체 구축</strong>하여 국내 AI 상담 챗봇의 새로운 문을 열고 있습니다.
                  </div>
                  <div className="item">
                    하잉만의 특화된 <strong>페르소나 Agent AI챗봇</strong>을 통해 보다 깊은 심리적 고민을 털어놓을 수 있습니다.
                  </div>
                </>
              ) : myLanguage === ENG ? (
                  <>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service1.png" alt="icon" /></div>
                      Whith the <strong>Hiing Agent AI chatbot.</strong> you can share everything from small daily worries to deeper psychological concerns and anxieties.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service2.png" alt="icon" /></div>
                      Hiing ensures <strong>character consistency</strong> while generating fitting images based on conversations.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service3.png" alt="icon" /></div>
                      Through eco-friendly NFC communication, Hiing allows you to interact with <strong>touchable figures</strong> to access our services seamlessly.
                    </div>
                    <div className="item">
                      <div className="icon"><img src="/images/sub/ico_item_service4.png" alt="icon" /></div>
                      Our technology automatically <strong>engages with fans on social media,</strong> fostering close connections through timely comments.
                    </div>
                  </>
                ) : null
            }
          </div>
          <div className="image-area">
            <img src="/images/sub/AITechnology_img_1.png" alt="tech" />
          </div>
        </div>
      </div>
      <div className="persona-box-wrap">
        <div className="persona-box service2">
          <div className="txt">
            {
              myLanguage === KOR ? (
                <>
                  <span>비글즈에서 더욱</span>
                  <span>
                    <strong>따뜻하고 가치 있는 서비스를</strong>
                  </span>
                  <span>제공하기 위해 오늘도 연구하고 있습니다.</span>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <h2>Chatbot</h2>
                    <p>Engage in meaningful conversations with our advanced AI chatbot, designed to provide empathetic and supportive dialogues. Tailored to your needs, our chatbot enhances your mental health journey.</p>
                  </div>
                  <div className="desc">
                    <p>Mental wellness bot</p>
                    <p>implemented with Bigglz’s</p>
                    <p className="highlight">specialized natural language</p>
                    <p className="highlight">processing technology</p>
                  </div>
                </>
              ) : null
            }
          </div>
          <div className="service-box">
            <div className="service-area">
              <div className="image"><img src="/images/eng/AITechnology_img_2.png" alt="AITechnology_img_2" /></div>
            </div>
          </div>
        </div>
      </div>
      <div className="persona-box-wrap">
        <div className="persona-box service3">
          <div className="service-box">
            <div className="service-area">
              <div className="image"><img src="/images/eng/AITechnology_img_4.png" alt="AITechnology_img_4" /></div>
            </div>
          </div>
          <div className="txt">
            {
              myLanguage === KOR ? (
                <>
                  <span>
                    관계 형성의 핵심은 <strong>공유된 경험</strong>입니다.
                  </span>
                  <span>
                    <span>사람과 함께 나눈 대화와 정보를 챗봇이 기억하고, 이를 토대로 대화를 이어나가는 것이 감정적 연결을 형성하는 것에 필수적입니다.</span>
                    <span>
                      비글즈 고유 RAG 접근 방식은 과거의 상호작용을 바탕으로
                      챗봇과 사용자가 대화할 수 있게하고, 트렌디한 최신 정보까지 다룰 수 있어
                      보다 편안하고 자유롭게 이야기할 수 있게 합니다.
                    </span>
                    <span>비글즈의 기술력이 탑재된 챗봇은 우리 삶에 꼭 필요한 존재가 될 것입니다.</span>
                  </span>
                </>
              ) : myLanguage === ENG ? (
                <>
                  <div className="tit">
                    <h2>Self</h2>
                    <h2>Compliment Diary</h2>
                  </div>
                  <div className="desc">
                    <p>Boost your confidence with
                    our self-compliment diary,
                    a digital space to record and reflect
                    on your achievements.
                    It’s your personal tool for building a positive mindset and enhancing
                    emotional resilience.</p>
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className="persona-box-wrap">
        <div className="persona-box service4">
          <div className="service-box">
            <div className="tit">
              <h2>Interactive</h2>
              <h2>Plush Toy</h2>
            </div>
            <div className="service-area">
              <div className="image"><img src="/images/eng/AITechnology_img_6.png" alt="AITechnology_img_6" /></div>
              <div className="desc">
                Meet our NFC-enabled plush toys that connect seamlessly with our app, providing a unique blend of physical comfort and digital engagement. These toys foster emotional intelligence and offer interactive support for a healthier, happier you.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="persona-box-wrap service5">
        <div className="persona-box service5">
          <div className="service-box">
            <div className="txt">
              <h2>Effortless Automated Social Media Responses</h2>
              <p>Our solution quietly and effectively automates responses using
              your account’s unique tone and knowledge. With real-time, multilingual support,
              it understands both text and images, ensuring thoughtful engagement.</p>
            </div>
            <div className="image-area">
              <div className="image"><img src="/images/eng/AITechnology_img_5.png" alt="AITechnology_img_5" /></div>
            </div>
            <div className="button">
              <button><a href={ outLinks.bigglzPetpal } target="_blank">Try AI Comments</a></button>
            </div>
          </div>
        </div>
      </div>
      <div className="persona-box-wrap service6">
        <div className="persona-box service6">
          <div className="service-box">
            <div className="tit">
              <p>Our solution quietly and effectively automates responses using
              your account’s unique tone and knowledge. With real-time, multilingual support,
              it understands both text and images, ensuring thoughtful engagement.</p>
            </div>
            <div className="service-area">
              <div className="item">
                <div className="icon"><img src="/images/sub/ico_item_service5_1.png" alt="icon" /></div>
                <div className="txt">
                  <h2>Expansion of IP business into chatbots</h2>
                  <p>We are developing a Persona AI Mental wellness bot for youth-targeted communication with companies that have famous international IPs.</p>
                </div>
              </div>
              <div className="item">
                <div className="icon"><img src="/images/sub/ico_item_service5_2.png" alt="icon" /></div>
                <div className="txt">
                  <h2>Interactive CS Solutions</h2>
                  <p>We are helping to advance services by suggesting a new CS solution based on Persona AI from the existing scenario-based CS.</p>
                </div>
              </div>
              <div className="item">
                <div className="icon"><img src="/images/sub/ico_item_service5_3.png" alt="icon" /></div>
                <div className="txt">
                  <h2>Provide counseling data</h2>
                  <p>We provide consultation data for machine learning and provide information so that the data can be processed according to user properties.</p>
                </div>
              </div>
              <div className="item">
                <div className="icon"><img src="/images/sub/ico_item_service5_4.png" alt="icon" /></div>
                <div className="txt">
                  <h2>Supporting Mental Health</h2>
                  <p>Our platform uses advanced AI to foster empathetic conversations with teens about their well-being, offering a cost-effective alternative to in-person counseling.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}