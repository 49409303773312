import React from "react";
import { BigglzVision, HistoryInfo, ComPartner } from "./components/about";
import { OfficePictureSwipe } from "./components/officePictureSwipe";
import { KakaoMap } from "./components/kakaoMap";

const AboutIndex = () => {
  return (
    <>
      <section className='about'>
        <article className='visual'>
          <h2>비글즈 소개</h2>
        </article>
        <article className='content'>
          <div className='box target-box'>
            <BigglzVision />
          </div>
          <div className='box crew-box'>
            <OfficePictureSwipe num={7} />
          </div>
          <div className="box history-box">
            <HistoryInfo />
          </div>
          <div className="box partner-box">
            <ComPartner />
          </div>
          <div className='box location-box'>
            <KakaoMap />
          </div>
        </article>
      </section>
    </>
  );
};

export default AboutIndex;
